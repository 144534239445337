import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/common/header"
import { Text, Div, Tag, Row, Col, Image, Link, Container } from "atomize"
import Footer from "../components/homepage/Footer"

const Policy = () => {
  return (
    <Layout>
      <Header layout="layout-2" />

      <div style={{ height: "36px" }} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Điều khoản sử dụng
        </Text>
        <p>
          Chào mừng quý khách hàng đến uCall - Giải pháp toàn diện giúp doanh
          nghiệp của bạn chăm sóc khách hàng 24/7.
        </p>
        <br />
        <p>
          Khi quý khách hàng truy cập vào trang trang web của chúng tôi có nghĩa
          là quý khách đồng ý với các điều khoản này.
        </p>
        <br />
        <p>
          Trang web có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần
          nào trên trang web này, vào bất cứ lúc nào. Các thay đổi có hiệu lực
          ngay khi được đăng trên trang web mà không cần thông báo trước. Và khi
          quý khách tiếp tục sử dụng trang web, sau khi các thay đổi về Điều
          khoản này được đăng tải, có nghĩa là quý khách chấp nhận với những
          thay đổi đó. Quý khách hàng vui lòng kiểm tra thường xuyên để cập nhật
          những thay đổi của chúng tôi.
        </p>
        <br />
        <br />
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Chính sách chung
        </Text>
        <p>
          Khi vào trang web của chúng tôi, khách hàng phải đảm bảo đủ 18 tuổi,
          hoặc truy cập dưới sự giám sát của cha mẹ hay người giám hộ hợp pháp.
          Khách hàng đảm bảo có đầy đủ hành vi dân sự để thực hiện các giao dịch
          theo quy định hiện hành của pháp luật Việt Nam.
        </p>
        <br />
        <p>
          Quý khách hàng sẽ phải đăng ký tài khoản với thông tin xác thực về bản
          thân và phải cập nhật nếu có bất kỳ thay đổi nào. Mỗi người truy cập
          phải có trách nhiệm với mật khẩu, tài khoản và hoạt động của mình trên
          web. Hơn nữa, quý khách hàng phải thông báo cho chúng tôi biết khi tài
          khoản bị truy cập trái phép. Chúng tôi không chịu bất kỳ trách nhiệm
          nào, dù trực tiếp hay gián tiếp, đối với những thiệt hại hoặc mất mát
          gây ra do quý khách không tuân thủ quy định.
        </p>
        <br />
        <p>
          Nghiêm cấm sử dụng bất kỳ phần nào của trang web này với mục đích
          thương mại hoặc nhân danh bất kỳ đối tác thứ ba nào nếu không được
          chúng tôi cho phép bằng văn bản. Nếu vi phạm bất cứ điều nào trong
          đây, chúng tôi sẽ hủy tài khoản của khách mà không cần báo trước.
        </p>
        <br />
        <p>
          Trong suốt quá trình đăng ký, quý khách đồng ý nhận email quảng cáo từ
          trang web. Nếu không muốn tiếp tục nhận mail, quý khách có thể từ chối
          bằng cách nhấp vào đường link ở dưới cùng trong mọi email quảng cáo.
        </p>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Thương hiệu và bản quyền
        </Text>
        <br />
        <p>
          Mọi quyền sở hữu trí tuệ (đã đăng ký hoặc chưa đăng ký), nội dung
          thông tin và tất cả các thiết kế, văn bản, đồ họa, phần mềm, hình ảnh,
          video, âm nhạc, âm thanh, mã nguồn và phần mềm cơ bản trên trang web
          này đều là tài sản của chúng tôi. Toàn bộ nội dung của trang web được
          bảo vệ bởi luật bản quyền của Việt Nam và các công ước quốc tế
        </p>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Quyền pháp lý
        </Text>
        <br />
        <p>
          Các điều kiện, điều khoản và nội dung của trang web này được điều
          chỉnh bởi luật pháp Việt Nam và Tòa án có thẩm quyền tại Việt Nam sẽ
          giải quyết bất kỳ tranh chấp nào phát sinh từ việc sử dụng trái phép
          trang web này.
        </p>
        <div style={{ height: "128px" }} />
      </Div>
      <Footer />
    </Layout>
  )
}

export default Policy
